@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'npm:animate.css';

@layer components {
  .timeline-row {
    @apply flex flex-wrap w-full -mt-[10px];

    &:first-child {
      @apply mt-0;

      .timeline-content,
      .timeline-date {
        @apply rounded-tl-none border-t-0;
      }
    }

    .timeline-date {
      @apply flex items-center w-full lg:w-3/12 px-5
      py-10 lg:px-20 lg:py-32 justify-start lg:justify-end;

      background: linear-gradient(#F4F0DF, #F4F0DF) padding-box, linear-gradient(to left, transparent, transparent 50%, #EF2C43 50%, #EF2C43) border-box;
      border-left: 10px solid transparent;

      @screen lg {
        border: 0;
      }

      .date {
        @apply bg-yappa-red py-5 px-10 rounded-lg text-base md:text-4xl font-bold text-white shadow-btn shadow-yappa-dark-red
      }
    }

    .timeline-content {
      @apply bg-beige w-full lg:w-9/12 lg:rounded-l-[3vh] px-5
      py-10 lg:px-20 lg:py-32 relative z-10;

      border-left: 10px solid #EF2C43;

      @screen lg {
        background: linear-gradient(#F4F0DF, #F4F0DF) padding-box, linear-gradient(to left, transparent, transparent 50%, #EF2C43 50%, #EF2C43) border-box;
        border: 10px solid transparent;
        border-right: 0;
      }
    }

    &:nth-child(2n) {
      @apply flex-row-reverse;

      .timeline-date {
        @apply justify-start;

        background: linear-gradient(#F4F0DF, #F4F0DF) padding-box, linear-gradient(to left, transparent, transparent 50%, #EF2C43 50%, #EF2C43) border-box;
        border-left: 10px solid transparent;

        @screen lg {
          border: 0;
        }
      }

      .timeline-content {
        @apply rounded-r-[3vh] flex-row-reverse;

        > div {
          @apply flex-row-reverse;
        }

        @screen lg {
          background: linear-gradient(#F4F0DF, #F4F0DF) padding-box, linear-gradient(to right, transparent, transparent 50%, #EF2C43 50%, #EF2C43) border-box;
          border: 10px solid transparent;
          border-left: 0;
        }
      }
    }

    &:last-child {
      .timeline-content {
        @apply rounded-bl-none rounded-br-none;
        border-bottom: 0;
      }
    }
  }
}
