:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0deg);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0deg);
  }
}

.animate__animated.animate__flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-family: quicksand, ui-sans-serif, system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

textarea {
  resize: vertical;
}

input::-ms-input-placeholder, textarea::-ms-input-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, .5);
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.aspect-w-16 {
  padding-bottom: calc(var(--tw-aspect-h) / var(--tw-aspect-w) * 100%);
  --tw-aspect-w: 16;
  position: relative;
}

.aspect-w-16 > * {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.aspect-h-9 {
  --tw-aspect-h: 9;
}

.timeline-row {
  width: 100%;
  flex-wrap: wrap;
  margin-top: -10px;
  display: flex;
}

.timeline-row:first-child {
  margin-top: 0;
}

.timeline-row:first-child .timeline-content, .timeline-row:first-child .timeline-date {
  border-top-width: 0;
  border-top-left-radius: 0;
}

.timeline-row .timeline-date {
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 2.5rem 1.25rem;
  display: flex;
}

@media (min-width: 1024px) {
  .timeline-row .timeline-date {
    width: 25%;
    justify-content: flex-end;
    padding: 8rem 5rem;
  }
}

.timeline-row .timeline-date {
  background: linear-gradient(#f4f0df, #f4f0df) padding-box padding-box, linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, #ef2c43 50%, #ef2c43) border-box;
  border-left: 10px solid rgba(0, 0, 0, 0);
}

@media (min-width: 1024px) {
  .timeline-row .timeline-date {
    border: 0;
  }
}

.timeline-row .timeline-date .date {
  --tw-bg-opacity: 1;
  background-color: rgb(239 44 67 / var(--tw-bg-opacity) );
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity) );
  --tw-shadow: 5px 5px 0 0 #a54141;
  --tw-shadow-colored: 5px 5px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  --tw-shadow-color: #a54141;
  --tw-shadow: var(--tw-shadow-colored);
  border-radius: .5rem;
  padding: 1.25rem 2.5rem;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

@media (min-width: 768px) {
  .timeline-row .timeline-date .date {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

.timeline-row .timeline-content {
  z-index: 10;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(244 240 223 / var(--tw-bg-opacity) );
  padding: 2.5rem 1.25rem;
  position: relative;
}

@media (min-width: 1024px) {
  .timeline-row .timeline-content {
    width: 75%;
    border-top-left-radius: 3vh;
    border-bottom-left-radius: 3vh;
    padding: 8rem 5rem;
  }
}

.timeline-row .timeline-content {
  border-left: 10px solid #ef2c43;
}

@media (min-width: 1024px) {
  .timeline-row .timeline-content {
    background: linear-gradient(#f4f0df, #f4f0df) padding-box padding-box, linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, #ef2c43 50%, #ef2c43) border-box;
    border: 10px solid rgba(0, 0, 0, 0);
    border-right: 0;
  }
}

.timeline-row:nth-child(2n) {
  flex-direction: row-reverse;
}

.timeline-row:nth-child(2n) .timeline-date {
  background: linear-gradient(#f4f0df, #f4f0df) padding-box padding-box, linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, #ef2c43 50%, #ef2c43) border-box;
  border-left: 10px solid rgba(0, 0, 0, 0);
  justify-content: flex-start;
}

@media (min-width: 1024px) {
  .timeline-row:nth-child(2n) .timeline-date {
    border: 0;
  }
}

.timeline-row:nth-child(2n) .timeline-content {
  border-top-right-radius: 3vh;
  border-bottom-right-radius: 3vh;
  flex-direction: row-reverse;
}

.timeline-row:nth-child(2n) .timeline-content > div {
  flex-direction: row-reverse;
}

@media (min-width: 1024px) {
  .timeline-row:nth-child(2n) .timeline-content {
    background: linear-gradient(#f4f0df, #f4f0df) padding-box padding-box, linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 50%, #ef2c43 50%, #ef2c43) border-box;
    border: 10px solid rgba(0, 0, 0, 0);
    border-left: 0;
  }
}

.timeline-row:last-child .timeline-content {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-20 {
  z-index: 20;
}

.order-first {
  order: -9999;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-20 {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.w-full {
  width: 100%;
}

.-rotate-\[13deg\] {
  --tw-rotate: -13deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-y-2 {
  row-gap: .5rem;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.rounded-lg {
  border-radius: .5rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.bg-beige {
  --tw-bg-opacity: 1;
  background-color: rgb(244 240 223 / var(--tw-bg-opacity) );
}

.bg-yappa-red {
  --tw-bg-opacity: 1;
  background-color: rgb(239 44 67 / var(--tw-bg-opacity) );
}

.bg-texture {
  background-image: url("texture.123e8e10.png");
}

.bg-radio {
  background-image: url("radio.3ae3d64e.png");
}

.bg-contain {
  background-size: contain;
}

.bg-\[length\:30\%\] {
  background-size: 30%;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-bottom {
  background-position: bottom;
}

.bg-left-bottom {
  background-position: 0 100%;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.object-cover {
  object-fit: cover;
}

.p-8 {
  padding: 2rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-20 {
  padding-right: 5rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.text-center {
  text-align: center;
}

.font-title {
  font-family: fairwater-script, ui-sans-serif, system-ui, AppleSystem, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-bold {
  font-weight: 700;
}

.text-beige {
  --tw-text-opacity: 1;
  color: rgb(244 240 223 / var(--tw-text-opacity) );
}

.text-yappa-green {
  --tw-text-opacity: 1;
  color: rgb(2 100 80 / var(--tw-text-opacity) );
}

.shadow-btn {
  --tw-shadow: 5px 5px 0 0 #a54141;
  --tw-shadow-colored: 5px 5px 0 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.shadow-dark-beige {
  --tw-shadow-color: rgba(92, 78, 35, .5);
  --tw-shadow: var(--tw-shadow-colored);
}

.backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia);
}

.transition-\[font-size\] {
  transition-property: font-size;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.transition {
  transition-property: color, background-color, border-color, -webkit-text-decoration-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

.hover\:translate-y-px:hover {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.hover\:translate-x-px:hover {
  --tw-translate-x: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
}

.hover\:shadow-none:hover {
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

@media (min-width: 640px) {
  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (min-width: 768px) {
  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .md\:min-h-\[97vh\] {
    min-height: 97vh;
  }

  .md\:w-auto {
    width: auto;
  }

  .md\:pt-10 {
    padding-top: 2.5rem;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

@media (min-width: 1024px) {
  .lg\:order-none {
    order: 0;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:-translate-x-10 {
    --tw-translate-x: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:translate-x-32 {
    --tw-translate-x: 8rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .lg\:gap-20 {
    gap: 5rem;
  }

  .lg\:gap-x-20 {
    column-gap: 5rem;
  }

  .lg\:gap-y-5 {
    row-gap: 1.25rem;
  }

  .lg\:bg-\[length\:50\%\] {
    background-size: 50%;
  }

  .lg\:bg-\[right_-100px_bottom_3vh\] {
    background-position: right -100px bottom 3vh;
  }

  .lg\:bg-\[right_-100px_bottom_0\] {
    background-position: right -100px bottom 0;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:pt-0 {
    padding-top: 0;
  }

  .lg\:pt-40 {
    padding-top: 10rem;
  }

  .lg\:pb-20 {
    padding-bottom: 5rem;
  }

  .lg\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }
}

@media (min-width: 1280px) {
  .xl\:ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:w-2\/5 {
    width: 40%;
  }

  .xl\:w-3\/5 {
    width: 60%;
  }

  .xl\:translate-x-48 {
    --tw-translate-x: 12rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .xl\:-translate-y-10 {
    --tw-translate-y: -2.5rem;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y) ) rotate(var(--tw-rotate) ) skewX(var(--tw-skew-x) ) skewY(var(--tw-skew-y) ) scaleX(var(--tw-scale-x) ) scaleY(var(--tw-scale-y) );
  }

  .xl\:text-8xl {
    font-size: 6rem;
    line-height: 1;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:text-9xl {
    font-size: 8rem;
    line-height: 1;
  }
}

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms;
}

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms;
}

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s;
}

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s;
}

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s;
}

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s;
}

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s;
}

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s;
}

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s;
}

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s;
}

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s;
}

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s;
}

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s;
}

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s;
}

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s;
}

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s;
}

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s;
}

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s;
}

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s;
}

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s;
}

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s;
}

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s;
}

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s;
}

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s;
}

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s;
}

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s;
}

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s;
}

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s;
}

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s;
}

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s;
}

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s;
}

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s;
}

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s;
}

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s;
}

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s;
}

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s;
}

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s;
}

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s;
}

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s;
}

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s;
}

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s;
}

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s;
}

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s;
}

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s;
}

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s;
}

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s;
}

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s;
}

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s;
}

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s;
}

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s;
}

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s;
}

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s;
}

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s;
}

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s;
}

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s;
}

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s;
}

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s;
}

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s;
}

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s;
}

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s;
}

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s;
}

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s;
}

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s;
}

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s;
}

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s;
}

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s;
}

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s;
}

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s;
}

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s;
}

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s;
}

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s;
}

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s;
}

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s;
}

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s;
}

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s;
}

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s;
}

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s;
}

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s;
}

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s;
}

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s;
}

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s;
}

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s;
}

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s;
}

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s;
}

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s;
}

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s;
}

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s;
}

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s;
}

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s;
}

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s;
}

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s;
}

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s;
}

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s;
}

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s;
}

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s;
}

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s;
}

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s;
}

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s;
}

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s;
}

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s;
}

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s;
}

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s;
}

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s;
}

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s;
}

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s;
}

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s;
}

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s;
}

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s;
}

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s;
}

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s;
}

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s;
}

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s;
}

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s;
}

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s;
}

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s;
}

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s;
}

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s;
}

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s;
}

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s;
}

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0s;
}

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s;
}

[data-aos] {
  pointer-events: none;
}

[data-aos].aos-animate {
  pointer-events: auto;
}

[data-aos][data-aos][data-aos-easing="linear"], body[data-aos-easing="linear"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .25, .75, .75);
}

[data-aos][data-aos][data-aos-easing="ease"], body[data-aos-easing="ease"] [data-aos] {
  transition-timing-function: ease;
}

[data-aos][data-aos][data-aos-easing="ease-in"], body[data-aos-easing="ease-in"] [data-aos] {
  transition-timing-function: ease-in;
}

[data-aos][data-aos][data-aos-easing="ease-out"], body[data-aos-easing="ease-out"] [data-aos] {
  transition-timing-function: ease-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-out"], body[data-aos-easing="ease-in-out"] [data-aos] {
  transition-timing-function: ease-in-out;
}

[data-aos][data-aos][data-aos-easing="ease-in-back"], body[data-aos-easing="ease-in-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

[data-aos][data-aos][data-aos-easing="ease-out-back"], body[data-aos-easing="ease-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-back"], body[data-aos-easing="ease-in-out-back"] [data-aos] {
  transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
}

[data-aos][data-aos][data-aos-easing="ease-in-sine"], body[data-aos-easing="ease-in-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.47, 0, .745, .715);
}

[data-aos][data-aos][data-aos-easing="ease-out-sine"], body[data-aos-easing="ease-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.39, .575, .565, 1);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-sine"], body[data-aos-easing="ease-in-out-sine"] [data-aos] {
  transition-timing-function: cubic-bezier(.445, .05, .55, .95);
}

[data-aos][data-aos][data-aos-easing="ease-in-quad"], body[data-aos-easing="ease-in-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quad"], body[data-aos-easing="ease-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quad"], body[data-aos-easing="ease-in-out-quad"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-cubic"], body[data-aos-easing="ease-in-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-cubic"], body[data-aos-easing="ease-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-cubic"], body[data-aos-easing="ease-in-out-cubic"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

[data-aos][data-aos][data-aos-easing="ease-in-quart"], body[data-aos-easing="ease-in-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.55, .085, .68, .53);
}

[data-aos][data-aos][data-aos-easing="ease-out-quart"], body[data-aos-easing="ease-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.25, .46, .45, .94);
}

[data-aos][data-aos][data-aos-easing="ease-in-out-quart"], body[data-aos-easing="ease-in-out-quart"] [data-aos] {
  transition-timing-function: cubic-bezier(.455, .03, .515, .955);
}

@media screen {
  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"] {
    opacity: 0;
    transition-property: opacity, transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos^="fade"][data-aos^="fade"].aos-animate {
    opacity: 1;
    transform: none;
  }

  html:not(.no-js) [data-aos="fade-up"] {
    transform: translate3d(0, 100px, 0);
  }

  html:not(.no-js) [data-aos="fade-down"] {
    transform: translate3d(0, -100px, 0);
  }

  html:not(.no-js) [data-aos="fade-right"] {
    transform: translate3d(-100px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-left"] {
    transform: translate3d(100px, 0, 0);
  }

  html:not(.no-js) [data-aos="fade-up-right"] {
    transform: translate3d(-100px, 100px, 0);
  }

  html:not(.no-js) [data-aos="fade-up-left"] {
    transform: translate3d(100px, 100px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-right"] {
    transform: translate3d(-100px, -100px, 0);
  }

  html:not(.no-js) [data-aos="fade-down-left"] {
    transform: translate3d(100px, -100px, 0);
  }

  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"] {
    opacity: 0;
    transition-property: opacity, transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos^="zoom"][data-aos^="zoom"].aos-animate {
    opacity: 1;
    transform: translateZ(0)scale(1);
  }

  html:not(.no-js) [data-aos="zoom-in"] {
    transform: scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-up"] {
    transform: translate3d(0, 100px, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-down"] {
    transform: translate3d(0, -100px, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-right"] {
    transform: translate3d(-100px, 0, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-in-left"] {
    transform: translate3d(100px, 0, 0)scale(.6);
  }

  html:not(.no-js) [data-aos="zoom-out"] {
    transform: scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-up"] {
    transform: translate3d(0, 100px, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-down"] {
    transform: translate3d(0, -100px, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-right"] {
    transform: translate3d(-100px, 0, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos="zoom-out-left"] {
    transform: translate3d(100px, 0, 0)scale(1.2);
  }

  html:not(.no-js) [data-aos^="slide"][data-aos^="slide"] {
    visibility: hidden;
    transition-property: transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos^="slide"][data-aos^="slide"].aos-animate {
    visibility: visible;
    transform: translateZ(0);
  }

  html:not(.no-js) [data-aos="slide-up"] {
    transform: translate3d(0, 100%, 0);
  }

  html:not(.no-js) [data-aos="slide-down"] {
    transform: translate3d(0, -100%, 0);
  }

  html:not(.no-js) [data-aos="slide-right"] {
    transform: translate3d(-100%, 0, 0);
  }

  html:not(.no-js) [data-aos="slide-left"] {
    transform: translate3d(100%, 0, 0);
  }

  html:not(.no-js) [data-aos^="flip"][data-aos^="flip"] {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition-property: transform, -webkit-transform;
  }

  html:not(.no-js) [data-aos="flip-left"] {
    transform: perspective(2500px)rotateY(-100deg);
  }

  html:not(.no-js) [data-aos="flip-left"].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }

  html:not(.no-js) [data-aos="flip-right"] {
    transform: perspective(2500px)rotateY(100deg);
  }

  html:not(.no-js) [data-aos="flip-right"].aos-animate {
    -webkit-transform: perspective(2500px) rotateY(0);
    transform: perspective(2500px) rotateY(0);
  }

  html:not(.no-js) [data-aos="flip-up"] {
    transform: perspective(2500px)rotateX(-100deg);
  }

  html:not(.no-js) [data-aos="flip-up"].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }

  html:not(.no-js) [data-aos="flip-down"] {
    transform: perspective(2500px)rotateX(100deg);
  }

  html:not(.no-js) [data-aos="flip-down"].aos-animate {
    -webkit-transform: perspective(2500px) rotateX(0);
    transform: perspective(2500px) rotateX(0);
  }
}

/*# sourceMappingURL=index.81c89d04.css.map */
